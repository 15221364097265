import { Grid, makeStyles, Box, Button, Container } from '@material-ui/core';
import ThankYouImage from './svg/thank.svg'

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		[theme.breakpoints.down('sm')]: {
			minHeight: '70vh',
		},
	},
	content: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
		height: '100%',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10)
        },
    },
	image: props => ({
		backgroundImage: `url(${props.image})`,
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: 'center',
		backgroundPositionY: 'top',
		backgroundSize: 'cover',
	}),
	topTitle: {
		fontSize: '18px',
		color: '#6D509B',
		fontFamily: 'SourceSansPro-Bold',
	},
	title: {
		marginTop: theme.spacing(1),
		fontSize: '36px',
		fontFamily: 'SourceSansPro-Bold',
		color: '#111747',
	},
	subTitle: {
		marginTop: theme.spacing(1),
		fontFamily: 'SourceSansPro-Regular',
		color: '#262B36',
		fontSize: '18px',
	},
	button: {
		width: '100%',
		padding: '10px 20px 10px 20px',
		backgroundColor: '#20D300',
		fontFamily: 'SourceSansPro-Bold',
		color: '#FFFFFF',
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: '#20D300',
		},
	}
}));

const title = "Nuova versione di Bandzai, nuovo dominio!"
const subTitle = "Gentile utente, approfittando delle meritate vacanze estive dei nostri early adopters, abbiamo lavorato sulla nostra infrastruttura IT per renderla ancora più veloce e performante in vista delle tante novità e degli aggiornamenti in rilascio da Settembre 2021 in poi!<br/><br/>Per questo, abbiamo completato la migrazione verso il nuovo dominio <a href=https://dashboard.bandzai.it>https://dashboard.bandzai.it</a>, dal quale potrai accedere alla tua area riservata.<br/><br/>Ti invitiamo dunque ad accedere alla tua area riservata dal nuovo dominio, utilizzando le <b>stesse credenziali</b> settate in fase di iscrizione.<br/><br/>Non farti sfuggire le tante opportunità di finanza agevolata che ti aspettano su Bandzai!"

function App() {

	const classes = useStyles({ image: ThankYouImage });



	return (
		<Grid container className={classes.root}>
			<Grid item xs={false} sm={false} md={5} lg={4} className={classes.image} />
			<Grid container item xs={12} sm={12} md={7} lg={8}>
				<Container maxWidth="xs">
					<Box display='flex' flexGrow={1} flexDirection='column' className={classes.content}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box display="flex" justifyContent="flex-start" className={classes.title}><div dangerouslySetInnerHTML={{ __html: title }} /></Box>
								<Box display="flex" justifyContent="flex-start" className={classes.subTitle}><div dangerouslySetInnerHTML={{ __html: subTitle }} /></Box>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<Button
									disableRipple
									className={classes.button}
									onClick={() => window.open('https://dashboard.bandzai.it', "_self")}>
									Accedi
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Grid>
		</Grid>
	);
}

export default App;
